// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a href="#" className="brand"
            target="_blank" rel="noopener noreferrer">
            <img className="App-cart" src="/icons/marshmallows.png" />
            Marshmallows
          </a>
          <a href="#" className="brand"
            target="_blank" rel="noopener noreferrer">
            <img className="App-cart" src="/icons/lollipops.png" />
            Lollipops
          </a>
          <a href="#" className="brand"
            target="_blank" rel="noopener noreferrer">
            <img className="App-cart" src="/icons/gummies.png" />
            Gummies
          </a>
          <a href="#" className="brand"
            target="_blank" rel="noopener noreferrer">
            <img className="App-cart" src="/icons/combos.png" />
            Combo Packs
          </a>
        </div> */}
        <img src="logo.png" className="App-logo" alt="logo" />
        <p style={{ marginTop: 0 }}>
          Our brand new website is under development.
        </p>
        <p style={{ marginTop: 0 }} className="smaller">Meanwhile, please feel free to shop our products from Amazon.</p>
        <a href="https://www.amazon.in/s?i=merchant-items&me=A3NY8SSJ0OUMN5&dc&marketplaceID=A21TJRUUN4KGV&qid=1606528455"
          className="App-link" target="_blank" rel="noopener noreferrer">
          <img alt="Shop Now at Amazon" className="App-cart" src="shopping-cart.png" />
          Shop Now
        </a>
      </header>
    </div>
  );
}

export default App;
